//const website = require('./website')

module.exports = {
  "fr-fr": {
    default: true,
    label: "Fr",
    path: "fr",
    locale: "fr-fr",
    siteLanguage: "fr",
    ogLang: "fr_FR",
    projects: "Projets",
    about: "À propos",
    contact: "Contact",
    title: "titre",
    theme: "thème",
    themes: "thèmes",
    year: "année",
    years: "années",
    localisation: "localisation",
    localisations: "localisations",
    superficie: "superficie",
    status: "statut",
    actualites: "actualités",
    distinctions: "distinctions",
    filtre: "Filtres",
    prevProject: "Projet précédent",
    nextProject: "Projet suivant",
    back: "Retour",
    recherche: "Recherche",
  },
  "en-gb": {
    default: false,
    label: "En",
    path: "en",
    locale: "en-gb",
    siteLanguage: "en",
    ogLang: "en_GB",
    projects: "Projects",
    about: "About",
    contact: "Contact",
    title: "title",
    theme: "theme",
    themes: "themes",
    year: "year",
    years: "years",
    localisation: "localisation",
    localisations: "localisations",
    superficie: "superficie",
    status: "status",
    actualites: "news",
    distinctions: "award",
    filtre: "Filters",
    prevProject: "Previous projet",
    nextProject: "Next projet",
    back: "Back",
    recherche: "Search",
  },
}
