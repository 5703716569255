import React from "react"
// import { ReactComponent as Logo } from "../images/logo.svg";
import logo from "../images/logo_agence_ter.svg"

const LogoSvg = () => (
  <svg
    id="f9d53c36-2e2e-46cb-8ae0-33c2f0b13249"
    data-name="Calque 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 213.93 44.48"
  >
    <title>logo_agence_ter</title>
    <path
      className="transparent"
      d="M62.53,13.18c-3.66,0-6.12,1.82-6.12,4.54,0,2.33,2.22,4,5.28,4h1.79c2.39,0,4.64-1.8,4.64-3.7C68.12,15.62,66.2,13.18,62.53,13.18Z"
    />
    <path
      className="pleins"
      d="M64.42,23l6.62.1c1.79,0,2.62-.7,2.62-2.19,0-2.89-2-10.4-11.35-10.4-7.43,0-11.87,4.91-11.87,13.13,0,7.53,4.66,12.4,11.87,12.4,6.4,0,9.75-3.16,9.75-4.86,0-.54-.35-1.11-1.34-1.11a2,2,0,0,0-1.51.53,2.4,2.4,0,0,1-.33.24l-2.74,1.8a6.35,6.35,0,0,1-3.52.69c-3.41,0-6.75-2.93-6.75-5.91C55.89,24.08,58,23,64.42,23M68.12,18c0,1.9-2.26,3.7-4.64,3.7H61.69c-3.06,0-5.27-1.69-5.27-4,0-2.72,2.46-4.54,6.12-4.54s5.59,2.44,5.59,4.85"
    />
    <path
      className="transparent"
      d="M11.89,22.45c-3.47,0-7.68,2.95-7.68,6.31a4.94,4.94,0,0,0,5,4.84c4,0,7.36-2.94,7.36-6.73A4.46,4.46,0,0,0,11.89,22.45Z"
    />
    <path
      className="pleins"
      d="M10.52,10.78c-3.79,0-8,1.37-8,3.47,0,.84.63.74,1.26.74,2.31,0,3.68-3.16,6.73-3.16h.84a5.22,5.22,0,0,1,5,5.47c0,2.21-2.73,4.21-7.68,4.84S0,25.19,0,29.5c0,3.78,3.15,6.1,6.94,6.1,3.37,0,4.21-1.47,7.36-1.47,1.68,0,3.58.74,4.63.74,1.26,0,1.89-.74,1.89-2.1V25.08c0-10.2.84-14.31-10.31-14.31M9.26,33.6a4.94,4.94,0,0,1-5-4.84c0-3.37,4.21-6.31,7.68-6.31a4.46,4.46,0,0,1,4.73,4.42c0,3.79-3.37,6.73-7.36,6.73"
    />
    <path
      className="transparent"
      d="M36.18,13.24A6.77,6.77,0,0,0,31,15.52a9.39,9.39,0,0,0-2.12,6.35c0,6,2.74,9.79,7.16,9.79a6.81,6.81,0,0,0,5.18-2.36,9,9,0,0,0,2.08-6C43.34,17.4,40.4,13.24,36.18,13.24Z"
    />
    <path
      className="pleins"
      d="M44.28,12a8.48,8.48,0,0,0-2.79.3,2.11,2.11,0,0,1-.68.12,21.72,21.72,0,0,1-3.24-.52l-.9-.22a13.27,13.27,0,0,0-3.12-.52,7.63,7.63,0,0,0-6.08,3.3,13.91,13.91,0,0,0-2.54,8.37c0,3.54.85,6.26,2.52,8.1a8.45,8.45,0,0,0,5.88,2.62,11.14,11.14,0,0,0,3.15-.33l0,0a18,18,0,0,1,2-.17c3,0,4.53,1.52,4.53,4.53,0,3.3-3.1,5.89-7.05,5.89-2.06,0-3.68-1.3-5.11-2.45-1.1-.88-2-1.65-3-1.65s-1.47.49-1.47,1.57c0,.52.61,1.41,1.95,2.14s3.43,1.43,7.31,1.43c8,0,11.35-2.3,11.35-7.67V14.72c0-1.64-.46-2.73-2.73-2.73m-.94,11.35a9,9,0,0,1-2.08,6,6.81,6.81,0,0,1-5.18,2.36c-4.42,0-7.16-3.75-7.16-9.79A9.39,9.39,0,0,1,31,15.52a6.78,6.78,0,0,1,5.15-2.28c4.21,0,7.16,4.15,7.16,10.1"
    />
    <path
      className="transparent"
      d="M134.9,20.89h1.79c2.07,0,4.09-1.53,4.09-3.08,0-2.1-1.73-4.22-5-4.22s-5.57,1.57-5.57,3.91C130.17,19.46,132.16,20.89,134.9,20.89Z"
    />
    <path
      className="pleins"
      d="M137.63,23.17l6.61.1c2.63,0,3.18-1.46,3.18-2.69,0-2.78-2.18-10.72-11.89-10.72-7.78,0-12.42,5-12.42,13.4,0,7.58,5,12.68,12.42,12.68,6.38,0,10.3-3.08,10.3-5.3A1.67,1.67,0,0,0,143.94,29a2.57,2.57,0,0,0-1.86.65,2.07,2.07,0,0,1-.26.19l-2.76,1.77a6.08,6.08,0,0,1-3.22.59c-3.13,0-6.2-2.6-6.2-5.25s1.2-3.8,8-3.8m-1.89-9.58c3.31,0,5,2.12,5,4.22,0,1.56-2,3.08-4.09,3.08H134.9c-2.74,0-4.73-1.43-4.73-3.39,0-2.34,2.24-3.91,5.57-3.91"
    />
    <path
      className="transparent"
      d="M182.36,18.46c3.39,0,5.14-.72,5.14-2.16,0-1.65-2.16-3.5-4.63-3.5-2.06,0-4.73,1.44-4.73,3.29C178.14,17.64,179.58,18.46,182.36,18.46Z"
    />
    <path
      className="pleins"
      d="M181.33,22.58H192c2.47,0,3.5-.62,3.5-3,0-2.06-1.44-12.14-12.65-12.14-6.69,0-13.06,4-13.06,14.5,0,8.74,5.66,13.37,13.17,13.37,7.71,0,11.42-4.63,11.42-6.27,0-1.23-1.85-2.67-3.5-2.67-2.67,0-3.39,3.6-7.82,3.6-2.67,0-5.35-2.47-5.35-4.73,0-1.75,1.23-2.67,3.6-2.67m1.54-9.77c2.47,0,4.63,1.85,4.63,3.5,0,1.44-1.75,2.16-5.14,2.16-2.78,0-4.22-.82-4.22-2.37,0-1.85,2.67-3.29,4.73-3.29"
    />
    <path
      className="pleins"
      d="M89.83,10.36a14.18,14.18,0,0,0-4.5.78,6.35,6.35,0,0,1-1.7.38,27.46,27.46,0,0,1-3.1-.22,23.62,23.62,0,0,0-2.58-.2,2.32,2.32,0,0,0-2.5,2.5V32.11c0,1.41.25,2.71,2.08,2.71a2.22,2.22,0,0,0,2.29-2.39V21.9c0-5,2.4-8.23,6.13-8.23,4,0,5.92,3,5.92,9.07L92,32.43c0,1.61.71,2.39,2.18,2.39s2.18-.95,2.18-2.81V17.8c0-3.19-.67-7.44-6.5-7.44"
    />
    <path
      className="pleins"
      d="M120,29.71a5.12,5.12,0,0,0-2.61.9A7.81,7.81,0,0,1,113,31.87c-4.82,0-7-4.41-7-8.79,0-5.05,2.86-8.58,7-8.58a8.8,8.8,0,0,1,4.09,1.31,4.84,4.84,0,0,0,2,.75c.7,0,2.82,0,2.82-2.14,0-2.46-4.56-4.61-9.76-4.61-8.87,0-12.91,7-12.91,13.48,0,8.18,4.95,13.27,12.91,13.27,5.7,0,9.86-3.31,9.86-5,0-.68-.27-1.83-2.08-1.83"
    />
    <path
      className="pleins"
      d="M165.13,27.15a5.55,5.55,0,0,1-.62-3.09v-6c0-1.75-.1-2.37.41-3.5.93-2.06,4.11-1.75,4.11-4.22s-3-2.47-3.8-3.7a5.45,5.45,0,0,1-.72-2.88c0-1.44-.31-3.81-3.91-3.81s-4,2.57-4,3.7a5.35,5.35,0,0,1-.51,2.78c-.41,1-3.09,2.06-3.09,3.81s1.85,2.47,2.67,3.5,1.13,2.57,1,4.63v9.25c0,5,2.88,7.61,8.54,7.61,2.78,0,4.11-1.13,4.11-3.29,0-3.08-3.39-3.08-4.22-4.83"
    />
    <path
      className="pleins"
      d="M211.36,8.07c-1.54,0-2,.62-3.5.62-1.23,0-2.47-1.13-5.66-1.13s-3.91,1.44-3.91,4V30.8c0,2.67,1,4.11,3,4.11h2.06c2.16,0,3.19-1.34,3.19-3.91V20.41c0-2.37,1.34-4.22,4-5s3.39-1.85,3.39-4.32c0-2-.82-3-2.57-3"
    />
  </svg>

  // <img src={logo} alt='logo' width='200' />;
)
export default LogoSvg
